import { extendTheme } from '@chakra-ui/react';

import baseTheme from '../../base-theme';

const alarmStatus = {
  FOR_REVIEW: {
    ...baseTheme['colors'].yellow,
    800: baseTheme['colors'].yellow['900'],
  },
  INCOMPLETE: {
    ...baseTheme['colors'].red,
    800: baseTheme['colors'].red['900'],
  },
  COMPLETE: {
    ...baseTheme['colors'].blue,
    800: baseTheme['colors'].blue['900'],
  },
};

const theme = extendTheme({
  ...baseTheme,
  colors: {
    ...baseTheme['colors'],
    ...alarmStatus,
    brand: {
      ...baseTheme['colors'].orange,
      topbar: baseTheme['colors'].orange['400'],
      25: baseTheme['colors'].orange['100'],
    },
  },
});

export default theme;
