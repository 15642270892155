import '@fontsource/lexend';
import * as ReactDOM from 'react-dom/client';

import { initializeApp } from '@cccom/config/app-init';

import App from './app/App';
import { environment } from './env';

async function main() {
  await initializeApp({
    mode: import.meta.env.MODE,
    appFamily: 'home',
    faroEndpoint: environment.FARO_ENDPOINT,
  });

  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );

  root.render(<App />);
}

main();
