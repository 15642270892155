// eslint-disable-next-line import/no-extraneous-dependencies
import { checkboxAnatomy } from '@chakra-ui/anatomy';
import { theme as proTheme } from '@chakra-ui/pro-theme';
import {
  createMultiStyleConfigHelpers,
  defineStyle,
  extendTheme,
} from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const baseTheme = extendTheme(proTheme);

const thin = definePartsStyle({
  control: defineStyle({
    '--checkbox-size': baseTheme['sizes']['5'],
    rounded: 'base',
    borderWidth: '1px',
    borderColor: baseTheme['colors'].gray['400'],
  }),
});

export const Checkbox = defineMultiStyleConfig({
  ...baseTheme['components'].Checkbox,
  baseStyle: {
    ...baseTheme['components'].Checkbox.baseStyle,
    control: {
      ...baseTheme['components'].Checkbox.baseStyle.control,
      _disabled: {
        bg: baseTheme['colors'].gray['200'],
        borderColor: baseTheme['colors'].gray['200'],
      },
    },
  },
  variants: { thin },
});
