import { AppProvider } from '@cccom/config/app-config';
import { homeTheme } from '@cccom/shared/themes';

import { environment } from '../env';
import { router } from './Routes';

function App() {
  return (
    <AppProvider
      theme={homeTheme}
      environment={environment}
      routerProps={{ router }}
    />
  );
}

export default App;
