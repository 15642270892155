/* eslint-disable import/no-extraneous-dependencies */
import { initializeFaro } from '@grafana/faro-web-sdk';
import { getFaroConfig, log } from '@cccom/shared/logging';

type InitializeAppProps = {
  mode: string;
  appFamily: string;
  faroEndpoint: string;
};

export async function initializeApp({
  mode,
  appFamily,
  faroEndpoint,
}: InitializeAppProps) {
  if (mode === 'mockApi') {
    // TODO CCCOM-4930 Make msw intialization generic
    if (appFamily === 'qi') {
      const { setupWorker } = await import('msw/browser');
      const { handlers } = await import(
        `../../../../${appFamily}/mocks/src/index.ts`
      );
      await setupWorker(...handlers).start();
      log.info('Mock API started');
      return;
    }

    const { worker } = await import(
      `../../../../${appFamily}/mocks/src/index.ts`
    );
    await worker.start();
    log.info('Mock API started');
  }

  if (mode === 'production') {
    initializeFaro(getFaroConfig(faroEndpoint));
  }
}
